import enlocal from "@/locales/EN.json";
const isI18n = (key) => {
  let result = enlocal[key];
  if (result) {
    return true;
  } else {
    return false;
  }
};
function getQueryString(url, name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = decodeURIComponent(url).substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return "";
}
function isString(value) {
  return Object.prototype.toString.call(value) === "[object String]";
}

function formatNumber(value, decimalPlaces = 2, showPlusSign = false) {
  // 尝试将传入的值转换为数字
  const numValue = parseFloat(value);

  // 检查是否转换成功且保留小数位数参数合法
  if (
    !isNaN(numValue) &&
    Number.isInteger(decimalPlaces) &&
    decimalPlaces >= 0
  ) {
    // 使用 toFixed() 方法保留指定位数的小数
    let formattedValue = numValue.toFixed(decimalPlaces);

    // 如果大于0且showPlusSign为true，则在前面添加'+'
    if (numValue > 0 && showPlusSign) {
      formattedValue = "+" + formattedValue;
    }

    return formattedValue;
  } else {
    // 返回默认值 '0.00'
    return "0.00";
  }
}

function getChangeColor(value) {
  if (value > 0) {
    return "#D23A35";
  } else if (value < 0) {
    return "#3AA346";
  } else {
    return "#333333";
  }
}
function getChangeColor2(valueStr) {
  const number = parseFloat(String(valueStr).replace(/[^0-9.-]/g, ''));
  if (isNaN(number)) {
    throw new Error('Invalid value string');
  }
  if (number > 0) {
    return "#D23A35";
  } else if (number < 0) {
    return "#3AA346";
  } else {
    return "#858C92";
  }
}
/**
 *
 * @param {当前页} page
 * @param {图片字段} fields
 * @returns imgobj中对应的图片地址
 */
function getImgURL(page, fields) {
  const value = useDeviceType().value;
  let equ = "";
  switch (value) {
    case "mobile":
      equ = "mo";
      break;
    case "pc":
      equ = "pc";
      break;
  }
  const lang = useI18n().locale.value;
  return imgList[lang][page][equ][fields];
}
export {
  getQueryString,
  isString,
  formatNumber,
  getChangeColor,
  isI18n,
  getImgURL,
  getChangeColor2
};
